<template>
	<div class="title">
		<span>当前位置：</span><span style="margin-left:10px">{{menuList}}</span>
		<span style="color:#555555;margin:0 8px;">></span>
		<span style="color:#555555">{{list}}</span>
		<span v-if="show" style="color:#555555;margin:0 8px;">></span>
		<span v-if="show" style="color:#555555">{{list1}}</span>
	</div>
</template>

<script>
	export default {
		props: {
			menuList: {
				type: String,
				default: ''
			},
			list: {
				type: String,
				default: ''
			},
			list1: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				show: false
			}
		},
		created() {
			if (this.list1) {
				this.show = true
			}
		}

	}
</script>

<style lang="less" scoped>
	.title {
		margin-top: 30px;
		color: #333333;
		font-size: 14px;
		margin-bottom: 20px;
	}
</style>
