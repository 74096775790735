<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="main">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
		</div>
		<div class="content main">
			<div class="noticeLeft">
				<div :class="viewShow ? 'isaActive' : ''" @click="newsEvt">{{notice}}</div>
				<div v-if="istoke&&isLogin" :class="!viewShow ? 'isaActive' : ''" @click="trainEvt">培训信息</div>
			</div>
			<div class="noticeRight" v-if="viewShow">
				<div v-if="newsList.length > 0">
					<div class="rightCard" @click="toDetail(it)" v-for="it in newsList" :key="it.id">
						<div class="info">
							<h3>{{it.title}}</h3>
							<h4>{{it.createTime}}</h4>
							<!-- <div style="width: 500px">创造性消费的方式第三方撒打发斯蒂芬但是发送到发送到发斯蒂芬是的发送到发送到发送到发斯蒂芬创造性消费的方式第三方撒打发斯蒂芬但是发送到发送到发斯蒂芬是的发送到发送到发送到发斯蒂芬创造性消费的方式第三方撒打发斯蒂芬但是发送到发送到发斯蒂芬是的发送到发送到发送到发斯蒂芬</div> -->
							<div class="imageCls">{{it.content}}</div>
							<div class="btnDetail">查看详情 ></div>
						</div>
							<div class="imgright">
									<img style="margin-top: 20px" v-if="it.image" :src="it.image" alt="">
						<img style="margin-top: 20px" v-else-if="!it.image" src="../../assets/imgs/5.jpg" alt="">
							</div>
					</div>
				</div>
				<div class="noTeacter" v-else-if="newsList.length === 0">
					<div class="noTeacter_img">
						<img src="../../assets/imgs/4.png" alt="">
						<div>抱歉，暂时没有您想要的内容</div>
					</div>
				</div>
			</div>
				<div class="noticeRight" v-if="!viewShow && istoke">
				<div v-if="trainList.length > 0">
					<div class="rightCard" @click="toDetail(it)" v-for="it in trainList" :key="it.id">
						<div class="info">
							<h3>{{it.title}}</h3>
							<h4>{{it.createTime}}</h4>
							<p class="imageCls">{{it.content}}</p>
							<div class="btnDetail">查看详情 ></div>
						</div>
							<div class="imgright">
							<img style="margin-top: 20px" v-if="it.image" :src="it.image" alt="">
						<img style="margin-top: 20px" v-else-if="!it.image" src="../../assets/imgs/5.jpg" alt="">
							</div>
					</div>
				</div>
				<div class="noTeacter" v-else-if="trainList.length === 0">
					<div class="noTeacter_img">
						<img src="../../assets/imgs/4.png" alt="">
						<div>抱歉，暂时没有您想要的内容</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total"
				:current-page.sync="currentPage" @current-change="handleCurrentChange">
			</el-pagination>
		</div>


		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex'
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "通知公告"],
				imgList: [{
						url: ''
					},
					{
						url: '1'
					},
					{
						url: '2'
					},
				],
				viewShow: true,
				newsList: [],
				trainList: [],
				total: 0,
				currentPage: 1,
				defaultImage: '',
				istoke: false,
				pageSize: 3,
				notice: ''
			}
		},
		created() {
			this.notice = localStorage.getItem('noticeTitle') || '新闻公告'
			if (localStorage.getItem('token')) {
				console.log('token');
				this.istoke = true
			}
			if (this.$route.query && this.$route.query.index === 1) {
				this.viewShow = true
			} else if (this.$route.query && this.$route.query.index === 2) {
				this.viewShow = false
			} else {
				this.$http.post('noticeApi', {
					type: 1,
					pageSize: this.pageSize,
					pageNumber: 1
				}).then(res => {
					if (res.code === 200) {
						this.newsList = res.data.records
						this.total = res.data.total
						// this.newsList.map(it => {
						//   it.createTime = it.createTime.split(' ')
						//   return it
						// })
					}
				})
			}

		},
		computed: {
			...mapState(['isLogin']),
		},
		methods: {
			//分页
			handleCurrentChange(e) {
				if (!!this.viewShow) {
					this.$http.post('noticeApi', {
						type: 1,
						pageSize: this.pageSize,
						pageNumber: e
					}).then(res => {
						if (res.code === 200) {
							this.newsList = res.data.records
							this.total = res.data.total
							this.newsList.map(it => {
								// it.createTime = it.createTime.split(' ')

								return it
							})
						}
					})
				} else {
					this.$http.post('noticeApi', {
						type: 2,
						pageSize: this.pageSize,
						pageNumber: e
					}).then(res => {
						if (res.code === 200) {
							this.trainList = res.data.records
							this.total = res.data.total
							this.trainList.map(it => {
								// it.createTime = it.createTime.split(' ')	
								return it
							})
						}
					})
				}
			},
			//新闻公告
			newsEvt() {
				this.viewShow = true
				this.$http.post('noticeApi', {
					type: 1,
					pageSize: this.pageSize
				}).then(res => {
					if (res.code === 200) {
						this.newsList = res.data.records
						this.total = res.data.total
						this.currentPage = 1
						// this.newsList.map(it => {
						//   it.createTime = it.createTime.split(' ')
						//   return it
						// })
					}
				})
			},
			//培训信息
			trainEvt() {
				this.viewShow = false
				this.$http.post('noticeApi', {
					type: 2,
					pageSize: this.pageSize
				}).then(res => {
					if (res.code === 200) {
						this.trainList = res.data.records
						this.total = res.data.total
						this.currentPage = 1
					}
				})
			},
			toDetail(it) {
				localStorage.setItem('notice', JSON.stringify(it))
				this.$router.push('/noticeCenter/noticeDetail')
			}
		}
	}
</script>

<style lang="less" scoped>
	.main {
		width: 1200px;
		margin: auto;
	}
	/deep/.el-carousel__button {
		background: #39AEFF;
	}
	.content {
		display: flex;
		justify-content: space-between;
	}
	.noticeLeft {
		width: 246px;
		// height: 150px;
		background: #FFFFFF;
		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
		div {
			width: 246px;
			height: 75px;
			background: #FFFFFF;
			text-align: center;
			line-height: 75px;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #333333;
			cursor: pointer;
			box-sizing: border-box;
			border-left: 6px solid #fff;
		}

		.isaActive {
			color: #39AEFF;
			border-left: 6px solid #39AEFF;
			box-sizing: border-box;
		}
	}

	.noticeRight {
		width: 1000px;
		padding-left: 40px;
		flex: 2;
		height: 650px;
		background: #FFFFFF;
		.rightCard {
				width: 100%;
			display: flex;
			margin: 15px 0;
			padding: 15px 0;
			cursor: pointer;
			border-bottom: 1px solid #EEEEEE;

			img {
				display: block;
				width: 216px;
				height: 130px;
			}

			.info {
				float: left;
				width: 70%;
				margin-left: 24px;
				padding-right: 40px;
				.btnDetail{
					width: 99px;
					height: 28px;
					line-height: 28px;
					text-align: center;
					background: #fff;
					color: #333333;
					font-size: 16px;
					margin-top: 35px;
					border: 1px solid #333333;
				}
					h3 {
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				margin-bottom: 16px;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			h4 {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #999999;
				margin-bottom: 16px;
			}

			.imageCls {
				font-size: 14px;
				min-height: 40px;
				max-height: 80px;
				// width: 500px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
				word-wrap:break-word;
				 text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
			}
			}
			.imgright{
				float: right;
				// width: 100%;
			}
		}

		.rightCard:hover {
			// width: 1144px;
			background: #EEEEEE;
			box-shadow: 0px 0px 20px 1px rgba(83, 83, 83, 0.08);
		}
		.rightCard:hover h3{
			color: #39AEFF;
		}
		.rightCard:hover .btnDetail{
			border: none;
			color: #fff;
			background-color: #39AEFF;
		}
	}

	/deep/.el-pagination {
		text-align: center;
		margin: 30px 0;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #39AEFF;
	}

	// .imageCls {
	// 	img {
	// 		width: 50px !important;
	// 		height: 50px !important;
	// 	}
	// }

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}
</style>
